<template>
  <b-modal
    visible
    scrollable
    centered
    size="xl"
    title="Врачи"
    hide-footer
    no-close-on-backdrop
    @hidden="onClose"
  >
    <custom-scrollbar>
      <Doctors
        :modal-name="modalName"
        :patient-id="patientId"
        :chat-appeal-id="chatAppealId"
        :opened-from-chat="openedFromChat"
        class="doctors"
      />
    </custom-scrollbar>
  </b-modal>
</template>

<script>
import Doctors from '@/pages/Doctors';

export default {
  name: 'DoctorsModal',
  components: {
    Doctors,
  },
  props: {
    modalName: {
      type: [String, Number],
      default: null,
    },
    patientId: {
      type: [String, Number],
      default: null,
    },
    chatAppealId: {
      type: [String, Number],
      default: null,
    },
    openedFromChat: {
      type: Boolean,
      default: false,
    },
  },
  data() {
    return {
      isOpen: true,
      isLoading: false,

    };
  },
  methods: {
    onClose() {
      this.$emit('input', false);
    },
  },
};
</script>

<style lang="scss" scoped>
  :deep(.doctor-list-inner) {
    padding-top: 10px;
  }
  .doctors {
    min-height: 90vh;
  }
</style>
